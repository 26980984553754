.centered_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 30px;
  min-height: calc(100vh - 75px); ; 
}

/* LOGIN - REGISTER */
.login__container {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 0px 10px rgba(0,0,0,0.05), 0 0px 10px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: row;
  min-height: 700px;
  width: 60%;
  overflow: hidden;
  margin: 3vw 0vw;
}

.login__image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dfdbee;
}

.login__image-container img{
  width: 130%;
}

.login__form-error {
  color: rgb(221, 0, 0);
  font-weight: bold;
  text-align: center;
}

.login__form-container {
  padding: 2% 4% !important; /*la mierda de bootstrap me lo cambia :/  */
  display: flex;
  justify-content: center;
  align-items: center;
}


.login__form-divider {
  margin: 10px auto;
  width: 80%;
}

.login__form-divider-or {
  text-align: center;
  font-size: 20px;
  columns: #313131;
  background-image:linear-gradient(#313131,#313131),linear-gradient(#313131,#313131);
  background-size:40% 1px;
  background-position:center left,center right;
  background-repeat:no-repeat;
}

.login__form-container  h2{
  margin: 0;
  font-size: 31pt;
  font-weight: bold;
}

.login__form-button {
  margin: 2.5% 0% !important;
}

.login__form-hasaccount {
  text-align:right;
}

.login__form-hasaccount > *{
  color: #313131;
  font-weight: 700;
}

.login__form-hasaccount > *:hover{
  color: #313131;
}

.login__image-arrowBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #313131;
  position: absolute;
  top: 1vw;
  left: 1vw;
}

.login__image-arrowBack:hover {
  color: #313131be;
}

.login__image-arrowBack > * {
  margin: 0px 3px;
  font-weight: 700;
}


@media only screen and (max-width: 1024px) {
  .login__container {
    width: 80%;
  }

}

@media only screen and (max-width: 350px) {
  .login__form-hasaccount {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) {
  .login__image-container {
    display: none;
  }

  .login__container {
    box-shadow: 0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0);
    width: 100%;
  }

  .login__form-container  h2{
    font-size: 21pt;
  }
}

/* END LOGIN - REGISTER */

/* 404 */
.error404__section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -175px;
}

.error404__title{
  color: #4c399f;
  font-weight: bolder;
  font-size: 35pt;
}

.error404__image {
  width: 40vw;
}

/* .centered_section-404 {
  min-height: calc(100vh - 75px); 
} */

@media only screen and (max-width: 750px) {
  .error404__image {
    width: 80vw;
  }

  .error404__section {
    margin-top: -75px;
  }

}



/* EMAIL CONFIRMATION SCREEN */
.email-confirmation__image{
  width: 20rem;
}

.email-confirmation__image-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-confirmation__title{
  text-align: center;
  color: #4c399f;
  font-weight: 700;
  font-size: 35pt;
}

.email-confirmation__description{
  text-align: center;
  font-size: 15pt;
  margin: 3vh 0;
}

.email-confirmation__button-col{
  padding: 0;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
}



@media only screen and (max-width: 330px) {
  .email-confirmation__image{
    width: 15rem !important;
  }
}