.content-table__container {
  border-radius: 10px;
  background-color: white;
  margin-top: 2rem;
  padding: 2rem;
  box-shadow: -11px 5px 70px -21px rgba(76, 57, 159,0.15);
  -webkit-box-shadow: -11px 5px 70px -21px rgba(76, 57, 159,0.15);
  -moz-box-shadow: -11px 5px 70px -21px rgba(76, 57, 159,0.15);
  border: 0px solid rgba(76, 57, 159,0);
}

.content-table__title {
  color: #2d384b;
  font-weight: bolder;
  font-size: 18pt;
}

.content-table__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.content-table__cell-buttoncontainer {
  display: flex;
  justify-content: space-around;
}

.content-table__search-section {
  margin-bottom: 1vh;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.content-table__cell-button {
  margin: 0 3px;
}

.content-table__table {
  table-layout: auto;
}

.content-table__th {
  padding: 0.5rem;
  border-bottom-width: 1px;
}

.content-table__table-row {
  vertical-align: middle;
  height: 55px;
}

.table>:not(caption)>*>* {
  padding: .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}


/* Sorting ( https://github.com/react-bootstrap-table/react-bootstrap-table2/blob/master/packages/react-bootstrap-table2/style/react-bootstrap-table2.scss) */

th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .caret-4-desc:after {
  content: "\2193";
}
