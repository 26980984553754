.sidebar__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #f4f5fa;
  padding: 5px 10px;
  margin: 10px 0px;
}

.sidebar__item-active {
  font-weight: bold;
  color: #f4f5fa;
  background-color: rgba(255, 255, 255, 0.1);
  border-left: 3px solid #F1490E;
}

.sidebar__item:hover {
  color: #f4f5fa;
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar__item-icon {
  margin: 0 5px 0 0;
}

.sidebar__item-title {
  visibility: visible;
  white-space: nowrap;
  font-size: 16pt;
  margin: 0;
  transition: 0.3s;
  transition: 0.3s;
}

.sidebar__item-title-hidden {
  visibility: hidden;
  opacity: 0;
  transition:  0.3s;
}

.sidebar__item-button {
  cursor: pointer;
}