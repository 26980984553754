
.dashboard__counter {
  background-color: white;
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  box-shadow: -5px 4px 39px -12px #4c399f2e;
  -webkit-box-shadow: -5px 4px 39px -12px #4c399f2e;
  -moz-box-shadow: -5px 4px 39px -12px #4c399f2e;
  padding: 1vh 1vw;
  margin: 1em;
  border-radius: .25rem;
  border-left: 4px solid #4c399f;
}

.dashboard__counters-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px;
}

.dashboard__counters-title>* {
  margin: 0;
}