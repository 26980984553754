.dashboard-template__sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #3C4B64;
  transition: 0.4s;
  will-change: auto;
  transition: 0.4s;
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-template__employees-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  transition: 0.4s;
  will-change: auto;
  transition: 0.4s;
  width: 230px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.dashboard-template__sidebar.hidden{
  width: 55px;
}

.dashboard-template__image {
  height:40px;
  transition: 0.7s;
}

.dashboard-template__image.hidden {
  opacity: 0%;
  overflow: hidden;
  transition: 0.1s;
}

.dashboard-template__sidebar-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 5px 10px 5px;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 53px;
}

/* HAMBURGER MENU */

.dashboard-template__ham-container {
  display: inline-block;
  cursor: pointer;
  margin: 10px 10px 0px 10px; 
}

.dashboard-template__ham-bar1, .dashboard-template__ham-bar2, .dashboard-template__ham-bar3 {
  width: 25px;
  height: 3px;
  background-color: #f4f5fa;
  margin: 5px 0;
  transition: 0.4s;
}

.dashboard-template__ham-active .dashboard-template__ham-bar1 {
  -webkit-transform: rotate(-45deg) translate(-2px, 1px);
  transform: rotate(-45deg) translate(-2px, 1px);
  width: 20px;
}

.dashboard-template__ham-active .dashboard-template__ham-bar2 {opacity: 0;}

.dashboard-template__ham-active .dashboard-template__ham-bar3 {
  -webkit-transform: rotate(47deg) translate(-1px, 0px);
  transform: rotate(47deg) translate(-1px, 0px);
  width: 20px;
}

.dashboard-template__sidebar-items {
  margin-top: 20px;
}

.dashboard-template__footer-text {
  white-space: nowrap;
  color: #7E8898;
}

.dashboard-template__footer-text-visible {
  display: inline;
  transition: 0.2s;
  white-space: nowrap;
}

.dashboard-template__footer-text-hidden {
  display: none;
  white-space: nowrap;
  /* opacity: 0%; */
  overflow: hidden;
  transition: 0.2s;
}

p.dashboard-template__footer-text {
  margin: 5px 5px 10px 5px; 
}

hr.dashboard-template__footer-text {
  height: 2px;
}

/* END HAMBURGER MENU */


.dashboard-template__main-container {
  transition: 0.4s;
  margin-left: 55px;
}

.dashboard-template__main-container-sidebar-open {
  margin-left: 230px ;
}

.dashboard-template__header {
  text-align: center;
  min-height: 53px;
  background-color: white;
  color: #4C399F;
  font-weight: bolder;
  font-size: 25pt;
}

.dashboard-template__content {
  padding: 1vw 2vw;
}

.dashboard-template__employees-sidebar-header {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 3px 15px 10px 15px;
  background-color: rgba(238, 238, 238, 0.54);
  width: 100%;
  height: 53px;
  font-weight: bold;
  border-left: 9px solid rgba(49, 49, 49, 0.11);;
}

.employees-sidebar__header-active {
  color: #8F98A5;
}

.employees-sidebar__header-active-number {
  font-size: 25pt;
}

.employees-sidebar__employees-section {
  padding: 0.5vw 0.5vw;
  display: flex;
  flex-direction: column;
  color: #4F5D73;
  font-weight: bold;
}

.employees-sidebar__employee {
  text-decoration: none;
  color: #4F5D73;
  margin: 0.2vw 0;
}
.employees-sidebar__employee:hover {
  color: #4f5d73d8;
}


.dashboard-section__container {
  margin-bottom: 1rem;
  border-radius: 10px;
  background-color: white;
  padding: 2em;
  box-shadow: -11px 5px 70px -21px rgba(76, 57, 159,0.15);
  -webkit-box-shadow: -11px 5px 70px -21px rgba(76, 57, 159,0.15);
  -moz-box-shadow: -11px 5px 70px -21px rgba(76, 57, 159,0.15);
  border: 0px solid rgba(76, 57, 159,0);
}
