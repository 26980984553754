.button__container {
  margin: 0px;
  -webkit-border-radius: 5;
  -moz-border-radius: 5;
  border-radius: 5px;
  font-size: 15px;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.button__container-filled {
  color: #ffffff;
  background: #4c399f;
  border: 1px solid #4c399f;
}

.button__container-unfilled {
  color: #372974;
  background: none;
  border: 1px solid #372974;
}

.button__container-filled:hover {
  background: #372974;
  text-decoration: none;
}

.button__container-unfilled:hover {
  color: #ffffff;
  background: #4c399f;
}

.button__container-large {
  width: 100%;
}

.button__container-disabled.button__container-filled {
  cursor: default;
  background: #4c399fc9;
  border: 1px solid #4c399f00; /* por alguna razon esto funciona :/ */
}

.button__container-disabled.button__container-unfilled {
  cursor: default;
  color: rgb(50 47 61 / 76%);
  background: none;
  border: 1px solid rgb(50 47 61 / 76%);
}

.button__container-disabled {
  cursor: default;
}

