.select__container {
  flex-direction: column;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
}

.select__label {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 12pt;
  color: #212529;
}

.select__select {
  cursor: pointer;
  border: 1px solid #00000000;
  background-color: #31313110;
  padding: 9px 18px;
  border-radius: 10px;
}
