.qr-section__container{
  display: flex;
  flex-direction: column; 
  align-items: center;
}

.qr-section__container > *{
  margin-bottom: 1vh;
}

.qr-section__button-container > * {
  margin: 2vh 5px;
}

.employee-detail__title {
  color: #2d384b;
  font-weight: bolder;
  margin: 0;
}

.employee-detail__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.employee-detail__action-section > * {
  margin: 0px 5px;
}

.employee-detail__image-arrowBack {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #2d384b;
  top: 1vw;
  left: 1vw;
}

.employee-detail__image-arrowBack:hover {
  color: #2d384bcc;
}

.employee-detail__image-arrowBack > * {
  margin: 0px 3px;
  font-weight: 700;
}

.employee-detail__qr-section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.dashboard__indicators-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.dashboard__graph-col {
  width: 500px; 
  min-width: 500px; 
  overflow: auto;
  margin-top: 2vh;
}

.dashboard__card {
  border-radius: 10px; 
  background-color: white; 
  padding: 2em; 
  margin-top: 3vh;
}